<template>
  <section>
    <b-collapse
      class="card accordion-card"
      animation="none"
      v-for="(collapse, index) of collapses"
      :key="index"
      :open="isOpen == index"
      @open="isOpen = index">
      <div
        slot="trigger"
        slot-scope="props"
        class="card-header"
        role="button">
        <a class="card-header-icon">
          <b-icon :icon="props.open ? 'menu-down' : 'menu-right'" />
        </a>
        <p class="card-header-title">
          {{ collapse.title }}
        </p>
      </div>
      <div :style="`margin: ${innerMargin}px`">
        <div class="content">
          <slot :name="`collapse-${index}`" />
        </div>
      </div>
    </b-collapse>
  </section>
</template>

<script>
export default {
  props: {
    collapses: {
      type: Array,
      required: true,
    },
    innerMargin: {
      type: Number,
      default: 20,
    },
  },
  data() {
    return {
      isOpen: 0,
    };
  },
};
</script>
<style scoped>
.card-header {
  background: whitesmoke;
}
.card-header-title {
  margin: 0!important;
  font-size: 120%;
}
</style>

<template>
  <div :class="`block-editor-wrapper ${expanded ? 'expanded' : ''}`">
    <portal to="blockEditorModals" v-if="showModals === true">
      <Modal class="delete-view-modal" :active="!!showDeleteConfirmModal" :width="600" @close="showDeleteConfirmModal = false">
        Voulez-vous vraiment supprimer la vue "{{value.title}}" ?
        <div class="field has-addons" style="float: right; margin-top: 20px;">
          <p class="control">
            <button class="button is-danger" @click="deletePanel">
              Oui
            </button>
          </p>
          <p class="control">
            <button class="button" @click="showDeleteConfirmModal = false">
              Non
            </button>
          </p>
        </div>
      </Modal>
      <div v-if="!showDeleteConfirmModal" class="block-editor-overlay" />
      <TemplateEditorModal :value="value" @input="$emit('input', $event)"/>
    </portal>
    <div class="block-editor" :style="`${statusMessage ? 'top: 80px;': '' }`">
      <div>
        <b-tooltip class="expand-button-tooltip" :label="expanded ? i18n.collapse_editor: i18n.expand_editor" position="is-right">
          <button class="button expand-button is-small" @click="expanded = !expanded">
            <i :class="`mdi mdi-arrow-expand-${expanded ? 'right': 'left' }`"/>
          </button>
        </b-tooltip>
        <div v-if="value">
          <portal-target name="portalComplexField" @change="isPortalActive = $event" />
          <b-tabs style="display: flex; height: 100%; position: absolute;" ref="tabs">
            <b-tab-item :label="i18n.general" class="general-tab" icon="cog">
              <vue-form-generator
                ref="form"
                :schema="generalSchema"
                :model="value"
                class="form"
                tag="div"
              />
            </b-tab-item>
            <b-tab-item :label="i18n.data" class="data-tab" icon="database">
              <vue-form-generator
                ref="form"
                :schema="dataSchema"
                :model="value"
                class="form"
                tag="div"
              />
           </b-tab-item>
            <b-tab-item :label="i18n.widget" class="widget-tab" icon="developer-board">
              <vue-form-generator
                ref="form"
                :schema="widgetSchema"
                :model="value"
                class="form"
                tag="div"
              />
            </b-tab-item>
            <b-tab-item label="Code" class="code-tab" icon="code-json">
              <codemirror
                v-model="valueAsJson"
                :options="codeEditorOptions"
                class="editor field-editor small field-footer"
                style="height: 100%"
              />
            </b-tab-item>
          </b-tabs>
          <div class="block-editor-buttons field has-addons">
            <p class="control">
              <button class="button is-success" @click="saveEditingView">
                <i class="mdi mdi-check" />
                {{i18n.apply}}
              </button>
            </p>
            <p class="control">
              <button class="button is-danger" @click="$store.commit('panels/setEditingView', false)">
                <i class="mdi mdi-close" />
                {{i18n.cancel}}
              </button>
            </p>
            <p class="control">
              <button class="button expand-button is-small" @click="showDeleteConfirmModal = true">
                <i class="mdi mdi-delete mdi-24px"/>
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import codemirror from '@/components/ui/Codemirror';
import Accordion from '@/components/ui/Accordion';
import JSON5 from 'json5';
import i18n from 'i18n/components/modals/BlockEditor.json';
import Modal from '@/components/modals/Modal';
import TemplateEditorModal from '@/components/modals/TemplateEditorModal';
import redirect from '@/core/utils/redirect';

import deepClone from '@/core/utils/deepClone';

export default {
  name: 'BlockEditor',
  props: {
    value: {
      type: Object,
      required: true,
    },
    showModals: {
      type: Boolean,
      default: true
    }
  },
  components: {
    codemirror,
    Accordion,
    Modal,
    TemplateEditorModal,
  },
  data() {
    return {
      i18n,
      templateEditorModalActive: false,
      showDeleteConfirmModal: false,
      expanded: false,
      isPortalActive: false,
      editorOptions: {
        tabSize: 2,
        lineWrapping: true,
        mode: { name: 'vue' },
        theme: 'idea',
        lineNumbers: true,
        line: true,
        keyMap: 'sublime',
        autoCloseBrackets: true,
      },
      codeEditorOptions: {
        tabSize: 4,
        mode: { name: 'javascript', json: true },
        theme: 'base16-dark',
        lineNumbers: true,
        lineWrapping: true,
        line: true,
        autofocus: true,
        keyMap: 'sublime',
        autoCloseBrackets: true,
      },
      generalSchema: {
        fields: [{
          type: 'iconSelector',
          inputType: 'text',
          label: i18n.icon,
          model: 'icon',
          position: 'is-bottom-left',
          styleClasses: ['quarter-width'],
        }, {
          label: i18n.title,
          model: 'title',
          type: 'text',
          styleClasses: ['three-quarters-width'],
        }, {
          label: 'template editor',
          model: 'templateEditor2',
          type: 'text',
        }, {
          label: i18n.expanded_view,
          model: 'fullWidth',
          type: 'checkbox',
        }, {
          type: 'text',
          label: i18n.background_image,
          model: 'backgroundUrl',
        }, {
          type: 'category',
          required: true,
          label: i18n.category,
          model: 'category',
        }, {
          label: i18n.group,
          model: 'group',
          type: 'text',
        }, {
          type: 'collection',
          required: true,
          label: i18n.collection,
          model: 'collection',
        }, {
          type: 'text',
          label: i18n.workgroup,
          model: 'workgroup',
        }, {
          type: 'text',
          label: i18n.default_filter,
          model: 'query',
        }, {
          type: 'checkbox',
          label: 'Désactiver l\'import de données',
          model: 'importDisabled',
        }],
      },
      dataSchema: {
        fields: [{
          label: i18n.schema,
          model: 'schema',
          type: 'document',
          required: true,
          labelField: 'name',
          relation: {
            type: 'hasOne',
            collection: 'schemas',
          },
          onlyFieldAsString: 'name',
          validatorFunction: {},
          help: i18n.schema_help,
          canCreateNewDocument: true,
        }],
      },
      widgetSchema: {
        fields: [{
          label: i18n.widget_type,
          type: 'select',
          values: ['list', 'kanban', 'calendar'],
          set: (model, v) => {
            if (!this.value.component) {
              this.$set(this.value, 'component', {});
            }
            this.$set(this.value.component, 'name', v);
            // this.value = deepClone(this.value);
          },
          get: () => (this.value.component ? this.value.component.name || 'list' : 'list'),
        }, {
          type: 'schemapropertyselector',
          label: i18n.column_field,
          model: 'component.categoryField',
          visible: (m) => m.component && m.component.name === 'kanban',
        }, {
          type: 'Array',
          label: i18n.columns,
          model: 'component.categories',
          showRemoveButton: true,
          itemContainerComponent: 'FieldArrayItemContainer',
          items: {
            type: 'text',
          },
          visible: (m) => m.component && m.component.name === 'kanban',
        }, {
          type: 'text',
          label: 'Propriété titre',
          model: 'component.titleKey',
          visible: (m) => m.component && m.component.name === 'calendar',
        }, {
          type: 'text',
          label: 'Propriété date',
          model: 'component.dateKey',
          visible: (m) => m.component && m.component.name === 'calendar',
        }, {
          type: 'text',
          label: 'Propriété couleur',
          model: 'component.colorKey',
          visible: (m) => m.component && m.component.name === 'calendar',
        }, {
          type: 'text',
          label: 'Propriété start date',
          model: 'component.startDateKey',
          visible: (m) => m.component && m.component.name === 'calendar',
        }, {
          type: 'text',
          label: 'Propriété end date',
          model: 'component.endDateKey',
          visible: (m) => m.component && m.component.name === 'calendar',
        }],
      },
    };
  },
  computed: {
    ...mapState({
      statusMessage: (state) => state.statusMessages.statusMessage,
    }),
    widgetType() {
      if (!this.value.component) return 'list';
      return this.value.component.name;
    },
    valueAsJson: {
      get() {
        return JSON5.stringify(this.value, null, 2);
      },
      set(v) {
        this.$emit('input', JSON5.parse(v));
      },
    },
  },
  methods: {
    async saveEditingView() {
      const res = await this.$store.dispatch('abstractElements/saveObject', {
        collection: 'panels',
        object: this.value,
      });
      if (res.payload.data && res.payload.data.success === true && res.payload.data.document) {
        this.$store.commit('panels/setEditingView', false);
      }
    },
    async deletePanel() {
      await this.$store.dispatch('abstractElements/deleteObject', {
        collection: 'panels',
        object: this.value,
      });
      this.$store.commit('panels/setEditedPanel', undefined);
      this.$store.commit('panels/setEditedElementPanel', undefined);
      this.$store.commit('panels/setEditingView', false);
      redirect(this.$router, ['/']);
    },
  },
};
</script>
<style scoped>
.block-editor-overlay {
  background: #00000050;
  position: fixed;
  top: 40px;
  left: 0;
  right: 400px;
  bottom: 0;
  z-index: 100000;
}
.delete-view-modal {
  z-index: 100001;
}
.expanded .block-editor-overlay {
  right: 700px;
}
.block-editor {
  position: fixed;
  top: 40px;
  height: calc(100% - 40px);
  right: 0;
  width: 400px;
  overflow-y: auto;
  z-index: 10000000;
  background: whitesmoke;
  box-shadow: 0px 0px 33px 0px rgba(163,163,163,1);
  font-size: 75%;
}
.expanded .block-editor {
  width: 700px;
}
.block-editor > div  {
  position: relative;
  background: white;
  margin-left: 4px;
  height: 100%;
}
.block-editor >>> .complex-field-editor {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background: white;
  z-index: 10000;
}
.block-editor >>> nav.tabs li span.icon {
  transform: rotate(90deg);
}
.block-editor >>> nav.tabs {
  background: #ccc;
  overflow: hidden;
  margin-top: 40px;
}
.block-editor >>> nav.tabs > ul {
  display: flex;
  margin: 0;
  flex-direction: column;
  width: 30px;
}
.block-editor >>> nav.tabs > ul > li > a {
  height: 140px;
  width: 30px;
  font-size: 80%;
  padding-top: 30px;
  position: relative;
}
.block-editor >>> nav.tabs > ul > li > a > span {
  transform: rotate(-90deg);
}
.block-editor >>> nav.tabs > ul > li > a > span.icon {
  transform: rotate(0);
  position: absolute;
  top: 6px;
  left: 2px;
}
.block-editor >>> nav.tabs li.is-active {
  background: white;
  color: black;
}
.form >>> .help {
  display: inline;
}
.block-editor >>> .tab-content {
  padding: 0;
  width: 366px;
}
.expanded .block-editor >>> .tab-content {
  width: 666px;
}
.block-editor >>> .tab-content > div {
  height: 100%;
}
.block-editor >>> .tab-content .code-tab > div .CodeMirror {
  height: calc(100% - 40px);
}
.block-editor >>> .general-tab, .block-editor >>> .widget-tab {
  padding: 12px;
}
.block-editor-buttons p {
  margin-bottom: 0;
  flex-grow: 1;
}
.block-editor-buttons p:last-child {
  flex-grow: 0;
  width: 40px;
}
.block-editor-buttons {
  margin: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.block-editor-buttons button {
  width: 100%;
}
.form >>> label {
  font-size: 120%;
  font-weight: bold;
}

.block-editor >>> .form-group.half-width {
  width: 50%;
}
.block-editor >>> .form-group.tier-width {
  width: 33.33%;
}
.block-editor >>> .form-group.two-tiers-width {
  width: 66.66%;
}
.block-editor >>> .form-group.quarter-width {
  width: 25%;
}
.block-editor >>> .form-group.three-quarters-width {
  width: 75%;
}
.expand-button-tooltip {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;
}
.expand-button {
  width: 30px;
  height: 40px;
  background: #777;
  color: white
}
.block-editor >>> .field-Array > .field-wrap > div {
  width: 100%;
}
.block-editor >>> .field-Array > .field-wrap > div > div {
  position:relative;
}

.block-editor >>> input[type="button"][value="x"] {
  position: absolute;
  right: 2px;
  top: 2px;
  bottom: 2px;
  z-index: 10000;
  margin: 2px;
  background: #aaa;
  border: 0;
  border-radius: 2px;
}
</style>
